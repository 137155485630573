<template>
    <setting-layout
        :title="$t('pages.channels.title')"
        :handle-back-by-emit="true"
        @back="$router.push({ name: 'user.own.messages' })"
    >
        <div class="grey--text text-body-2 mt-4 ml-16 mr-6">
            {{ $t("pages.channels.description.forEveryone") }}
        </div>

        <v-container class="pt-8">
            <div
                v-for="(item, i) in messages
                    .filter((chat) => chat.isBroadcastingChannel)
                    .sort((a, b) => b.isChatOwner - a.isChatOwner)"
                :key="i"
                class="message-container"
                :class="{ 'accent-background': item.isChatOwner }"
                :style="
                    item.isChatOwner
                        ? 'border-radius: 16px; margin-bottom: 8px;'
                        : ''
                "
            >
                <v-row
                    align="center"
                    :class="{ deletec: true, delete: deleteItem === i }"
                >
                    <v-col :class="{'pa-0' : item.isChatOwner}" cols="2">
                        <router-link
                            :to="{
                                name: 'otherUser.profile.viewSlug',
                                params: {
                                    slug: item.slug,
                                    from: 'user.own.messages',
                                },
                            }"
                        >
                            <icon-signal
                                v-if="item.isChatOwner"
                                class="ml-8 mt-2"
                            />
                            <profile-image
                                v-else
                                :src="item.image"
                                :username="item.name"
                            />
                        </router-link>
                    </v-col>
                    <v-col cols="8">
                        <router-link
                            :to="{
                                name: 'user.own.channel',
                                params: { chat_id: item.id },
                            }"
                        >
                            <v-row no-gutters>
                                <v-col
                                    style="display: flex; align-items: center"
                                >
                                    <v-badge
                                        v-if="item.unread_messages > 0"
                                        :content="item.unread_messages"
                                        :offset-x="-4"
                                        :offset-y="10"
                                        color="#FE4811"
                                    >
                                        <span class="name-sender font-weight-bold">
                                            {{ $t('pages.channels.other_user_broadcast', { user_name: item.name }) }}
                                        </span>
                                    </v-badge>
                                    <span v-else class="name-sender">
                                        {{
                                            item.isChatOwner
                                                ? $t(
                                                      "pages.channels.yourChannel"
                                                  )
                                                : $t('pages.channels.other_user_broadcast', { user_name: item.name })
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row v-if="!item.isChatOwner" no-gutters>
                                <v-col>
                                    <span class="text-message">
                                        {{ text(item.text) }}
                                    </span>
                                </v-col>
                            </v-row>
                        </router-link>
                    </v-col>
                    <v-icon v-if="item.isChatOwner" size="33" class="ml-2">
                        mdi-chevron-right
                    </v-icon>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </v-container>
        <verify-dialog
            v-if="this.isShowVerifyDialog"
            @close="handleCloseVerifyDialog"
        />
        <change-price-dialog
            v-if="this.isShowChangePriceDialog"
            @close="handleCloseChangePriceDialog"
        />
        <noodzlers-cant-turn-off-messages
            v-if="this.isShowNoodzlersCantTurnOffMessagesDialog"
            @close="handleCloseNoodzlersCantTurnOffMessagesDialog"
        />
    </setting-layout>
</template>

<script>
import IconSignal from "@/assets/images/icons/signal.svg?inline";
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog";
import ChangePriceDialog from "@/components/app/dialogs/ChangePriceDialog";
import NoodzlersCantTurnOffMessages from "@/components/app/dialogs/NoodzlersCantTurnOffMessages.vue";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import { mapActions, mapMutations, mapState } from "vuex";
import { CURRENCY_SYMBOL } from "@/configs/constants";

export default {
    name: "Messages",
    data: () => ({
        deleteItem: -1,
        price: "",
        turnOff: false,
        isMounted: false,
        isShowChangePriceDialog: false,
        isShowNoodzlersCantTurnOffMessagesDialog: false,
        currency: CURRENCY_SYMBOL,
        errors: {},
        search: "",
    }),
    watch: {
        price(val) {
            if (val > 0) {
                this.turnOff = false;
                this.setTurnOffMessage({
                    model: {
                        turnOff: this.turnOff,
                    },
                });
            }
        },
    },
    computed: {
        ...mapState({
            isShowVerifyDialog: (state) =>
                state.user.components.isShowVerifyDialog,
            user: (state) => state.user.user.data,
            messages: (state) => state.user.user.messages,
        }),
        isDisabledTurnOff() {
            return this.price > 0;
        },
    },
    methods: {
        ...mapMutations(["showSnackBar", "hideVerifyDialog"]),
        handleCloseVerifyDialog() {
            this.hideVerifyDialog();
        },
        handleCloseChangePriceDialog() {
            this.isShowChangePriceDialog = false;
        },
        handleCloseNoodzlersCantTurnOffMessagesDialog() {
            this.isShowNoodzlersCantTurnOffMessagesDialog = false;
        },
        text(text) {
            if (text?.length > 50) return text.substr(0, 50) + "...";
            return text;
        },
        swipeMessage(direction, id) {
            if (direction === "L") this.deleteItem = id;
            else this.deleteItem = -1;
        },
        deleteChat(id, chat_id) {
            this.messages.splice(id, 1);
            this.deleteItem = -1;
            this.deleteMessage(chat_id).then(() => {
                this.refreshData();
            });
        },
        refreshData() {
            this.getCurrentUserData().then((res) => {
                this.price = res.data.directMessagePrice;
                this.turnOff = res.data.directMessageTurnOff;
            });
        },
        async handleTurnOff() {
            this.errors = {};
            if (this.price > 0) {
                this.turnOff = !this.turnOff;
                return;
            }
            const model = {
                turnOff: !!this.turnOff,
            };
            this.setTurnOffMessage({
                model: model,
            })
                .then(() => {
                    this.showSnackBar(this.$t("messages.success"));
                    this.refreshData();
                })
                .catch((e) => {
                    this.errors = e.response.data.errors;
                });
        },
        handleClick() {
            this.isVerifyUser();
        },
        handleClickTurnOff() {
            if (this.user.verifyOndatoAt) {
                this.isShowNoodzlersCantTurnOffMessagesDialog = true;
                return;
            }

            if (this.isDisabledTurnOff) {
                this.isShowChangePriceDialog = true;
            }
        },
        onChangeSearch() {
            this.setMessages(this.search);
        },
        ...mapActions([
            "setMessages",
            "deleteMessage",
            "setTurnOffMessage",
            "getCurrentUserData",
            "isVerifyUser",
        ]),
    },
    async mounted() {
        await this.refreshData();
        await this.setMessages();
        this.isMounted = true;
    },
    components: {
        TextField,
        SettingLayout,
        VerifyDialog,
        ChangePriceDialog,
        ProfileImage,
        NoodzlersCantTurnOffMessages,
        IconSignal,
    },
};
</script>

<style scoped lang="scss">
$image-size: 50px;

.Messages {
    &-price {
        width: 100%;
        max-width: 126px;
    }
}

.profile-image {
    width: $image-size;
    height: $image-size;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
}

.search-field {
    border-radius: 16px;
}

.text-message {
    color: rgba(255, 255, 255, 0.5);
    line-height: 1.2em;
    font-size: 0.9em;
    margin-top: 5px;
    word-break: break-word;
}

.delete {
    transform: translateX(-63px);
    transition: 0.5s;
}

.deleteIcon {
}

.message-container {
    position: relative;
}

.delete-icon-container {
    position: absolute;
    /* top: 1.2em; */
    width: 100%;
    text-align: center;
    /* background: orange; */
    z-index: 0;
    left: 42px;
    height: 100%;
    top: 0;
    bottom: 0;
}

.delete-icon-background {
    background: #fe4811;
}

.deletec {
    transition: 0.5s;
}
</style>
