var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('setting-layout',{attrs:{"title":_vm.$t('pages.channels.title'),"handle-back-by-emit":true},on:{"back":function($event){return _vm.$router.push({ name: 'user.own.messages' })}}},[_c('div',{staticClass:"grey--text text-body-2 mt-4 ml-16 mr-6"},[_vm._v(" "+_vm._s(_vm.$t("pages.channels.description.forEveryone"))+" ")]),_c('v-container',{staticClass:"pt-8"},_vm._l((_vm.messages
                .filter(function (chat) { return chat.isBroadcastingChannel; })
                .sort(function (a, b) { return b.isChatOwner - a.isChatOwner; })),function(item,i){return _c('div',{key:i,staticClass:"message-container",class:{ 'accent-background': item.isChatOwner },style:(item.isChatOwner
                    ? 'border-radius: 16px; margin-bottom: 8px;'
                    : '')},[_c('v-row',{class:{ deletec: true, delete: _vm.deleteItem === i },attrs:{"align":"center"}},[_c('v-col',{class:{'pa-0' : item.isChatOwner},attrs:{"cols":"2"}},[_c('router-link',{attrs:{"to":{
                            name: 'otherUser.profile.viewSlug',
                            params: {
                                slug: item.slug,
                                from: 'user.own.messages',
                            },
                        }}},[(item.isChatOwner)?_c('icon-signal',{staticClass:"ml-8 mt-2"}):_c('profile-image',{attrs:{"src":item.image,"username":item.name}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('router-link',{attrs:{"to":{
                            name: 'user.own.channel',
                            params: { chat_id: item.id },
                        }}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"}},[(item.unread_messages > 0)?_c('v-badge',{attrs:{"content":item.unread_messages,"offset-x":-4,"offset-y":10,"color":"#FE4811"}},[_c('span',{staticClass:"name-sender font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('pages.channels.other_user_broadcast', { user_name: item.name }))+" ")])]):_c('span',{staticClass:"name-sender"},[_vm._v(" "+_vm._s(item.isChatOwner ? _vm.$t( "pages.channels.yourChannel" ) : _vm.$t('pages.channels.other_user_broadcast', { user_name: item.name }))+" ")])],1)],1),(!item.isChatOwner)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"text-message"},[_vm._v(" "+_vm._s(_vm.text(item.text))+" ")])])],1):_vm._e()],1)],1),(item.isChatOwner)?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"33"}},[_vm._v(" mdi-chevron-right ")]):_vm._e(),_c('v-spacer')],1)],1)}),0),(this.isShowVerifyDialog)?_c('verify-dialog',{on:{"close":_vm.handleCloseVerifyDialog}}):_vm._e(),(this.isShowChangePriceDialog)?_c('change-price-dialog',{on:{"close":_vm.handleCloseChangePriceDialog}}):_vm._e(),(this.isShowNoodzlersCantTurnOffMessagesDialog)?_c('noodzlers-cant-turn-off-messages',{on:{"close":_vm.handleCloseNoodzlersCantTurnOffMessagesDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }